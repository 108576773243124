<template>
  <div class="container-fluid my-3">
    <b-skeleton-wrapper :loading="ProdByCatLoading">
      <template #loading>
        <b-container fluid>
          <landingLoader></landingLoader>
        </b-container>
      </template>
      <b-row>
        <b-col
          sm="3"
          v-if="!ProdByCatLoading && items != null && items.length > 0"
        >
          <SidePage
            @Change-Search="ChangeSearch"
            :max="maxProdPrice"
          ></SidePage>
        </b-col>
        <b-col sm="9">
          <b-container>
            <b-row>
              <div
                v-if="!ProdByCatLoading && items != null"
                class="ProdList container"
              >
                <b-row>
                  <b-col
                    sm="3"
                    cols="6"
                    class="px-1 my-1"
                    v-for="Product in itemsForList"
                    v-bind:key="Product.id"
                  >
                    <ProdCard v-bind:Product="Product"></ProdCard>
                  </b-col>
                </b-row>
                <b-row align-h="center">
                  <b-pagination
                    v-if="!ProdByCatLoading && items.length > 0"
                    class="my-3 p-0"
                    v-model="currentPage"
                    :total-rows="items.length"
                    :per-page="perPage"
                    aria-controls="my-cards"
                  ></b-pagination>
                </b-row>
              </div>
            </b-row>
          </b-container>
          <!-- 
          <div v-for="item in ProdByCatLayout.mobile" v-bind:key="item.title">
            <banner
              v-bind:item="item"
              v-if="item.type === 'banner' && item.mobileDisplay !== 'hide'"
            ></banner>
            <ProdList
              v-bind:ProdList="item"
              v-if="item.type === 'ProdList' && item.mobileDisplay !== 'hide'"
            ></ProdList>
          </div>

          <div v-for="item in ProdByCatLayout.desktop" v-bind:key="item.title">
            <banner
              v-bind:item="item"
              v-if="item.type === 'banner' && item.Display !== 'hide'"
            ></banner>
            <ProdList
              v-bind:ProdList="item"
              v-if="item.type === 'ProdList' && item.Display !== 'hide'"
            ></ProdList>
          </div> -->
        </b-col>
      </b-row>
      <b-row
        v-if="ProdByCatLayout.category == null || items.length == 0"
        align-h="center"
      >
        <EmptyPage />
      </b-row>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import banner from "../components/widgets/Banner.vue";
import landingLoader from "../components/widgets/landingLoader.vue";
// import ProdList from "../components/lists/ProdList.vue";
import ProdCard from "../components/items/ProdCard.vue";
import SidePage from "../components/widgets/SidePage.vue";
import EmptyPage from "../components/widgets/EmptyPage.vue";

export default {
  name: "ProdByCat",

  computed: {
    ...mapGetters(["ProdByCatLayout"]),
    itemsForList() {
      return this.items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  components: {
    // ProdList,
    ProdCard,
    // banner,
    landingLoader,
    SidePage,
    EmptyPage,
  },
  metaInfo: {
    title: "Category-Products",
  },
  methods: {
    ...mapActions(["getProdByCat"]),
    getResults(page = 1) {
      var ProdByCat = this.$route.params.ProdByCat;
      var obj = {
        Cat: ProdByCat,
        breakpoint: this.$mq,
        Where: "ProdByCat",
        page: page,
        filter: this.searchform,
      };
      this.getProdByCat(obj);
    },
    ChangeSearch(sform) {
      this.searchform = sform;
      this.getResults();
    },
  },
  mounted() {
    var catId = this.$route.params.ProdByCat;
    var message = "Products By Category " + catId + " Page";
    this.$gtag.event(message, { method: "Google" });

    this.getResults();
  },
  data() {
    return {
      items: [],
      perPage: 12,
      currentPage: 1,
      ProdByCatLoading: true,
      searchform: {
        minprice: 0.1,
        maxprice: 2000,
        highRate: 5,
        lowRate: 0,
        limit: 20,
        sort: "DESC",
        condition: "New",
      },
      maxProdPrice: 0,
    };
  },
  watch: {
    currentPage() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 300);
    },
    ProdByCatLayout(newValue) {
      if (newValue) {
        this.ProdByCatLoading = false;
        this.items = newValue.category;
        this.maxProdPrice = newValue.maxPrice;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },

    "$route.params.ProdByCat"() {
      this.ProdByCatLoading = true;
      var obj = {
        Cat: this.$route.params.ProdByCat,
        breakpoint: this.$mq,
        Where: "ProdByCat",
        filter: this.searchform,
      };
      this.currentPage = 1;
      return this.getProdByCat(obj);
    },
  },
};
</script>

<style scoped>
.CatListHeader {
  font-size: 20px;
  font-weight: bold;
  margin: 6px;
}

.CatListBody ul li a {
  text-decoration: none;
  color: #646464;
}

.ProdByCatItem {
  margin: 4px 0;
}

.SubCat {
  margin: 8px 0;
  background: white;
  border-radius: 8px;
  border-color: #e7e7e7;
  border-width: 1px;
  border-style: solid;
}
</style>
